import { DateTime } from 'luxon'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import useCurrentUser from '@hooks/useCurrentUser'

const useCurrentDate = () => {
  const { currentUser: { timeZone } } = useCurrentUser()

  const { startToday } = parsePermittedQueryParams(getQueryParamsFromHash(), ['startToday'])

  const now = DateTime.now().setZone(timeZone)
  const today = now.startOf('day')
  const firstCalendarDay = () => {
    const firstDay = today.weekday >= 5 ? today.set({ weekday: 7 }) : today
    const daysOffset = startToday ? 0 : now > today.set({ hour: 15, minute: 0 }) || today.weekday > 5 ? 2 : 1
    const hardcodedStart = DateTime.fromISO('2025-01-28T00:00:00.000', { zone: timeZone })
    if (now > DateTime.fromISO('2025-01-24T15:00:00.000', { zone: timeZone })
      && firstDay.plus({ days: daysOffset }) < hardcodedStart){
      return hardcodedStart
    }

    return firstDay.plus({ days: daysOffset })
  }
  const defaultOffset = startToday ? 0 : firstCalendarDay().diff(today, ['days']).values.days

  const validateBookings = bookingDates => !bookingDates.some(x => DateTime.fromISO(x, { zone: timeZone }).startOf('day') < firstCalendarDay().startOf('day'))

  return {
    callbacks: {
      validateBookings: bookingDates => validateBookings(bookingDates),
    },
    defaultOffset,
    firstCalendarDay,
    now,
    today,
  }
}

export default useCurrentDate
